import React, { Component } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import { Row, Col } from "styled-bootstrap-grid"
import ArrowDown from "../../resources/img/icons/arrow-down.svg"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import { withTranslation } from "react-i18next"

const Textarea = styled.textarea`
  height: 227px;
  border: 1px solid #DEE2E8;
  font-size: 14px;
  color: ${colors.darkBlue};
  line-height: 24px;
  border-radius: 3px;
  width: 100%;
  display: block;
  clear: both;
  margin-bottom: 0;
  outline: 0;
  box-sizing: border-box;
  resize: none;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
  ${fonts.dmSansRegular};
  ::placeholder {
    color: ${colors.grey};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${colors.darkBlue};
      -webkit-box-shadow: 0 0 0 1000px ${colors.white} inset;
      transition: background-color 0s ease-in-out 0s;
  }
  @media(min-width: ${breakpoints.lg}){
      height: 144px;
      width: 95%;
  }
`

const Input = styled.input`
    font-size: 14px;
    line-height: 24px;
    height: 42px;
    border-radius: 3px;
    border: 1px solid #DEE2E8;
    width: 100%;
    display: block;
    clear: both;
    margin-bottom: 30px;
    outline: 0;
    box-sizing: border-box;
    padding-left: 18px;
    padding-top: 8px;
    padding-bottom: 10px;
    color: ${colors.darkBlue};
  ::placeholder {
    color: ${colors.grey};
  }
  
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${colors.darkBlue};
  }
  @media(min-width: ${breakpoints.lg}){
      width: 90%;
  }
`

const HoneyPot = styled.div`
  display: none;
`

const MessageStyled = styled.div`
  height: 50px;
  color: ${colors.darkBlue};
  p {
    margin-bottom: 0;
  }
`

const Form = styled.form`
    text-align: left;
`
const Label = styled.label`
    font-size: 12px;
    color: ${colors.grey};
    letter-spacing: 0;
    line-height: 16px;
    text-align: left;
    display: block;
    padding-bottom: 9px;
`

const Select = styled.select`
    border: 1px solid #DEE2E8;
    background: transparent;
    background: url(${ArrowDown}) no-repeat 97% center;
    background-size: 10px 10px;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    border-radius: 3px;
    width: 100%;
    padding-left: 18px;
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
    height: 42px;
    display: block;
    clear: both;
    margin-bottom: 30px;
    
    color: ${props => props.isEmpty? colors.grey : colors.darkBlue };
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
    @media(min-width: ${breakpoints.lg}){
        width: 90%;
    }
`

const SubmitBtn = styled.button`
    cursor: pointer;
    background-color: ${props => props.colour === 'white' ? colors.white: colors.blue};
    ${fonts.dmSansMedium};
    font-size: 11px;
    color: ${props => props.colour === 'white' ? colors.blue: colors.white};
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 12px 18px;
    border: 1px solid ${colors.blue};
    border-radius: 19px;
    transition: .5s;
    margin-right: 7.5px;
    margin-left: 7.5px;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background-color: ${props => props.colour === 'white' ? colors.blue : colors.white};
        color: ${props => props.colour === 'white' ? colors.white: colors.blue};
        transition: .5s;
        :after {
            content: '';
            -webkit-tap-highlight-color: transparent;
        }
    }
    @media(min-width: ${breakpoints.md}){
        padding: 12px 25px;
    }
`

const initialState = {
  name: "",
  position: "",
  email: "",
  subject:"",
  message: "",
  formSubmitted: false,
  success: false,
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = initialState

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    let target = event.target
    let stateObject = {}
    stateObject[target.name] = target.value

    this.setState(stateObject)
  }

  handleClick(e) {
    if (e) {
      e.preventDefault()
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    let contactForm = document.getElementById("contact-form")
    let formData = new FormData(contactForm)

    fetch(contactForm.getAttribute("action"), {
      method: "POST",
      body: formData,
    }).then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          this.setState(initialState)

          this.setState({
            formSubmitted: true,
            success: true,
          })

          let dataLayer = window.dataLayer
          if (typeof dataLayer !== "undefined") {
            dataLayer.push({
              "event": "formSubmitted",
              "formName": "Contact Us",
            })
          }
        } else {
          this.setState({
            formSubmitted: true,
            success: false,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Form method="POST" name="contact" data-netlify="true" id="contact-form"
              data-netlify-honeypot="bee" onSubmit={this.handleSubmit}>
          <Row>
            <Col lg={6}>
              <Label>{t("contact.form.name")}</Label>
              <Input id="name" type="text" name="name" placeholder="John Doe" value={this.state.name}
                     onChange={this.handleChange} required/>
            </Col>
            <Col lg={6}>
              <Label>{t("contact.form.position")}</Label>
              <Select name="position" onChange={this.handleChange} isEmpty={this.state.position === ""} defaultValue={t("contact.form.select")}>
                <option disabled>{t("contact.form.select")}</option>
                <option>{t("contact.form.subject.position.management")}</option>
                <option>{t("contact.form.subject.position.freight")}</option>
                <option>{t("contact.form.subject.position.other")}</option>
              </Select>
            </Col>
            <Col lg={6}>
              <Label>{t("contact.form.email")}</Label>
              <Input id="email" type="email" name="email" placeholder="john.doe@freightshipping.co.uk" value={this.state.email}
                     onChange={this.handleChange} required/>
            </Col>
            <Col lg={6}>
              <Label>{t("contact.form.subject")}</Label>
              <Select name="subject" onChange={this.handleChange} isEmpty={this.state.subject === ""} defaultValue={t("contact.form.select")}>
                <option disabled>{t("contact.form.select")}</option>
                <option>{t("contact.form.subject.interested")}</option>
                <option>{t("contact.form.subject.technical")}</option>
                <option>{t("contact.form.subject.other")}</option>
              </Select>
            </Col>
            <Col lg={12}>
              <Label>{t("contact.form.message")}</Label>
              <Textarea id="message" name="message" placeholder={t("contact.form.placeholder.message")} value={this.state.message}
                        onChange={this.handleChange} required/>
              <HoneyPot>
                <input name="bee"/>
              </HoneyPot>
              <input type="hidden" name="form-name" value="contact"/>
              <MessageStyled>
                {this.state.formSubmitted === true && this.state.success === true &&
                <p>{t("contact.form.success")}</p>
                }
                {this.state.formSubmitted === true && this.state.success === false &&
                <p>{t("contact.form.error")}</p>
                }
              </MessageStyled>
            </Col>
          </Row>
          <SubmitBtn type="submit"
                  onMouseDown={this.handleClick}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                      this.handleClick()
                    }
                  }}>
            {t("contact.form.submit")}
          </SubmitBtn>
        </Form>
      </div>
    )
  }
}

export default withTranslation()(ContactForm)
