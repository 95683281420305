import React, { Component } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import { Row, Col, Container } from "styled-bootstrap-grid"
import ContactForm from "../components/contact/ContactForm"


const PageContainer = styled(Container)`
    padding: 188px 0 90px 0;
    text-align: center;
    @media(min-width: ${breakpoints.lg}){
        padding-top: 217px;
        padding-bottom: 145px;
    }
`

const StyledRow = styled(Row)`
    margin: 0 33px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
    @media(min-width: ${breakpoints.xl}){
        margin: 0 33px;
    }
    @media(min-width: ${breakpoints.xxl}){
        margin: 0;
    }
`

const PageTitle = styled.h1`
    font-size: 42px;
    color: ${colors.darkBlue};
    letter-spacing: -1.35px;
    line-height: 52px;
    ${fonts.jaggerRegular};
    border-left: 2px solid ${colors.blue};
    padding-left: 18px;
    max-width: 273px;
    margin-bottom: 60px;
    text-align: left;
    @media(min-width: ${breakpoints.xl}){
        font-size: 62px;
        letter-spacing: -2px;
        line-height: 72px;
        margin-bottom: 0;
    }
`

const Address = styled.div`
    margin-top: 66px;
    text-align: left;
    ${fonts.dmSansRegular};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    @media(min-width: ${breakpoints.lg}){
        margin-top: 73px;
    }
`

const Email = styled.a`
    margin: 10px auto;
    text-decoration: none;
    display: block;
    color: ${colors.blue};
`

const PhoneNumber = styled.a`
    text-decoration: none;
    display: block;
    color: ${colors.darkBlue};
`

const DesktopOnly = styled.div`
    display: none;
    @media(min-width: ${breakpoints.lg}){
        display: block;
    }
`

const MobileOnly = styled.div`
    display: block;
    @media(min-width: ${breakpoints.lg}){
        display: none;
    }
`

class ContactPage extends Component {
  render() {
    const { t, i18n } = this.props


    return (
      <Layout meta={{
        uid: 'contact',
        lang: i18n.language === 'en' ? 'en-us' : 'fr-fr',
        alternateLanguages:[{ uid: 'contact', lang: i18n.language === 'en' ? 'fr-fr': 'en-us' }],
      }}>
        <SEO title={t("contact.title")}/>
        <PageContainer>
          <StyledRow>
            <Col lg={4} sm={12}>
              <PageTitle>{t("contact.form.title")}</PageTitle>
              <DesktopOnly>
                <Address>
                  Orly Head Office<br/>
                  40, rue d'Arcueil, CP 70163<br/>
                  94533 RUNGIS, FRANCE<br/>
                  <Email href="mailto:contact@myfreightonline.com">contact@myfreightonline.com</Email>
                  <PhoneNumber href="tel:+33 1 41 73 10 60">+33 (0)1 41 73 10 60</PhoneNumber>
                </Address>
              </DesktopOnly>
            </Col>
            <Col lg={7} sm={12}>
              <ContactForm />
            </Col>
            <Col lg={4}>
              <MobileOnly>
                <Address>
                  Orly Head Office<br/>
                  40, rue d'Arcueil, CP 70163<br/>
                  94533 RUNGIS, FRANCE<br/>
                  <Email href="mailto:contact@myfreightonline.com">contact@myfreightonline.com</Email>
                  <PhoneNumber href="tel:+33 1 41 73 10 60">+33 (0)1 41 73 10 60</PhoneNumber>
                </Address>
              </MobileOnly>
            </Col>
          </StyledRow>
        </PageContainer>
      </Layout>
    )
  }
}

export default withTranslation()(ContactPage)

